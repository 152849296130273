
import Vue from 'vue'
import { pagingBuilder, sortingBuilder } from 'kwik-vue/utils/tables'
import { KwikTooltipButtonIcon, ActiveFilterItem, IHeaderOption, IPagingInput, ISortingInput, KwikMediumContainer, KwikPageToolbar } from 'kwik-vue'
import { IOfferingGetInput } from '../types'
import { dispatchGetOfferingsCurrentPage } from '../store/actions'
import { readOfferingsCount, readOfferingsCurrentPage } from '../store/getters'
import { FilterOption } from '@/components/filtersBar/FilterOptions'
import { FilterTypes } from '@/components/filtersBar/FilterTypes'
import FiltersBar from '@/components/filtersBar/FiltersBar.vue'
import { clockToFormatDate } from '@/utils/clockManager'

export default Vue.extend({
  name: 'FFOfferings',
  components: { KwikMediumContainer, KwikPageToolbar, KwikTooltipButtonIcon, FiltersBar },
  watch: {
    options: {
      async handler () {
        await this.loadPage()
      },
      deep: true
    },
    filters: {
      async handler () {
        await this.loadPage()
      },
      deep: true
    }
  },
  data () {
    return {
      options: {} as any,
      loading: true,
      filters: [] as ActiveFilterItem[],
      showFilters: true,
      breadcrumbs: [
        { text: 'Food Factory', disabled: true },
        { text: 'Offerte', disabled: false }
      ]
    }
  },
  computed: {
    headers () : IHeaderOption[] {
      const defaultHeaders = [
        {
          text: 'ID',
          sortable: true,
          value: 'id',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Cliente',
          sortable: true,
          value: 'customer.business_name',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Prodotto',
          sortable: true,
          value: 'customer_request.product.name',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Stato',
          sortable: true,
          value: 'status',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Quantità',
          sortable: true,
          value: 'quantity',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Prezzo unitario',
          sortable: true,
          value: 'unit_price',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Data di consegna',
          sortable: true,
          value: 'due_date',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Data di scadenza',
          sortable: true,
          value: 'product_expiration',
          hidden: false,
          align: 'start'
        }
      ] as IHeaderOption[]
      return defaultHeaders
    },
    offeringsItems () {
      return readOfferingsCurrentPage(this.$store)
    },
    total () {
      return readOfferingsCount(this.$store)
    },
    filterOptions (): FilterOption[] {
      return [
        {
          key: 'customer_id',
          label: 'Cliente',
          field: 'customer_id',
          type: FilterTypes.CUSTOMER
        },
        {
          key: 'status',
          label: 'Stato',
          field: 'status',
          type: FilterTypes.OFFERING_STATE
        }
      ] as FilterOption[]
    }
  },
  methods: {
    async loadPage () {
      this.loading = true
      const paging: IPagingInput = pagingBuilder(this.options)
      const sorting: ISortingInput = sortingBuilder(this.options)
      let customerId = null
      let status = null
      if (this.filters) {
        this.filters.forEach((filter) => {
          switch (filter.field) {
            case 'customer_id':
              customerId = filter.value
              break
            case 'status':
              status = filter.value
              break
            default:
            // nothing
          }
        })
      }
      const payload: IOfferingGetInput = {
        paging: paging,
        sorting: sorting,
        customer_id: customerId,
        status: status
      }
      await dispatchGetOfferingsCurrentPage(this.$store, payload)
      this.loading = false
    },
    toggleFilter () {
      this.showFilters = !this.showFilters
    },
    filterData (activeFilters: ActiveFilterItem[]) {
      this.filters = activeFilters
    },
    clockToFormatDate (clock: number) {
      return clockToFormatDate(clock, 'shortDateTime')
    }
  }
})
